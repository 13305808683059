@use "sass:meta" as ---g5gaorbcwz;@import "utils/vars";

@for $i from 0 through 60 {
  // margins
  .m-#{$i * 4} {
    margin: rem-calc($i * 4);
  }
  .mb-#{$i * 4} {
    margin-bottom: rem-calc($i * 4);
  }
  .mt-#{$i * 4} {
    margin-top: rem-calc($i * 4);
  }
  .ml-#{$i * 4} {
    margin-left: rem-calc($i * 4);
  }
  .mr-#{$i * 4} {
    margin-right: rem-calc($i * 4);
  }
  // paddings
  .p-#{$i * 4} {
    padding: rem-calc($i * 4);
  }
  .pb-#{$i * 4} {
    padding-bottom: rem-calc($i * 4);
  }
  .pt-#{$i * 4} {
    padding-top: rem-calc($i * 4);
  }
  .pl-#{$i * 4} {
    padding-left: rem-calc($i * 4);
  }
  .pr-#{$i * 4} {
    padding-right: rem-calc($i * 4);
  }

  //border-radius
  .br-top-left-#{$i * 4} {
    border-top-left-radius: rem-calc($i * 4);
  }

  .br-top-right-#{$i * 4} {
    border-top-right-radius: rem-calc($i * 4);
  }

  .br-bottom-left-#{$i * 4} {
    border-bottom-left-radius: rem-calc($i * 4);
  }
  .br-bottom-right-#{$i * 4} {
    border-bottom-right-radius: rem-calc($i * 4);
  }

  // gap
  .gap-#{$i * 4} {
    gap: rem-calc($i * 4);
  }
}

@each $width in 25, 50, 75, 100 {
  .w-#{$width} {
    width: $width * 1%;
  }
}

.w-min-content {
  width: min-content;
}

@include breakpoint(large down) {
  @for $i from 0 through 60 {
    // margins
    .large-mb-#{$i * 4} {
      margin-bottom: rem-calc($i * 4);
    }

    .large-mt-#{$i * 4} {
      margin-top: rem-calc($i * 4);
    }

    .large-ml-#{$i * 4} {
      margin-left: rem-calc($i * 4);
    }

    .large-mr-#{$i * 4} {
      margin-right: rem-calc($i * 4);
    }

    // paddings
    .large-pb-#{$i * 4} {
      padding-bottom: rem-calc($i * 4);
    }

    .large-pt-#{$i * 4} {
      padding-top: rem-calc($i * 4);
    }

    .large-pl-#{$i * 4} {
      padding-left: rem-calc($i * 4);
    }

    .large-pr-#{$i * 4} {
      padding-right: rem-calc($i * 4);
    }

    .large-gap-#{$i * 4} {
      gap: rem-calc($i * 4);
    }
  }
}

@include breakpoint(medium down) {
  @for $i from 0 through 60 {
    // margins
    .medium-mb-#{$i * 4} {
      margin-bottom: rem-calc($i * 4);
    }

    .medium-mt-#{$i * 4} {
      margin-top: rem-calc($i * 4);
    }

    .medium-ml-#{$i * 4} {
      margin-left: rem-calc($i * 4);
    }

    .medium-mr-#{$i * 4} {
      margin-right: rem-calc($i * 4);
    }

    // paddings
    .medium-pb-#{$i * 4} {
      padding-bottom: rem-calc($i * 4);
    }

    .medium-pt-#{$i * 4} {
      padding-top: rem-calc($i * 4);
    }

    .medium-pl-#{$i * 4} {
      padding-left: rem-calc($i * 4);
    }

    .medium-pr-#{$i * 4} {
      padding-right: rem-calc($i * 4);
    }

    .medium-gap-#{$i * 4} {
      gap: rem-calc($i * 4);
    }
  }
}

@include breakpoint(small down) {
  @for $i from 0 through 60 {
    // margins
    .small-mb-#{$i * 4} {
      margin-bottom: rem-calc($i * 4);
    }

    .small-mt-#{$i * 4} {
      margin-top: rem-calc($i * 4);
    }

    .small-ml-#{$i * 4} {
      margin-left: rem-calc($i * 4);
    }

    .small-mr-#{$i * 4} {
      margin-right: rem-calc($i * 4);
    }
    // paddings

    .small-pb-#{$i * 4} {
      padding-bottom: rem-calc($i * 4);
    }

    .small-pt-#{$i * 4} {
      padding-top: rem-calc($i * 4);
    }

    .small-pl-#{$i * 4} {
      padding-left: rem-calc($i * 4);
    }

    .small-pr-#{$i * 4} {
      padding-right: rem-calc($i * 4);
    }

    .small-gap-#{$i * 4} {
      gap: rem-calc($i * 4);
    }
  }
}

@include breakpoint(medium down) {
  .medium-order--1 {
    order: -1
  }
}

.margin-auto {
  margin: 0 auto;
}

.margin-auto-top {
  margin-top: auto;
}

// colors
.color-black {
  color: $c-black;
}
.color-white {
  color: $c-white;
}
.color-text {
  color: $c-primary;
}
.color-text-secondary {
  color: $c-secondary;
}
.color-icons {
  color: $c-icons;
}
.color-orange {
  color: $c-orange;
}
.color-green {
  color: $c-green;
}
.color-gray {
  color: $c-gray;
}
.color-gray-15 {
  color: $c-gray-15;
}
.color-gray-20 {
  color: $c-gray-20;
}
.color-gray-30 {
  color: $c-gray-30;
}
.color-gray-35 {
  color: $c-gray-35;
}
.color-gray-40 {
  color: $c-gray-40;
}
.color-gray-50 {
  color: $c-gray-50;
}
.color-gray-60 {
  color: $c-gray-60;
}
.color-gray-65 {
  color: $c-gray-65;
}
.color-gray-70 {
  color: $c-gray-70;
}
.color-gray-80 {
  color: $c-gray-80;
}
.color-gray-90 {
  color: $c-gray-90;
}
.color-gray-100 {
  color: $c-gray-100;
}
.color-red {
  color: $c-red;
}
.color-light-red {
  color: $c-light-red;
}
.color-blue {
  color: $c-blue;
}
.color-dark-blue {
  color: $c-dark-blue;
}
.color-icons {
  color: $c-icons;
}
.color-purple {
  color: $c-purple;
}
.color-yellow {
  color: $c-yellow;
}
.color-headings {
  color: $c-headings;
}
.color-rose {
  color: $c-rose;
}

// platform colors

// text
.c-text-platform-default {
  color: getColor($c-text-default);
}
.c-text-platform-secondary {
  color: getColor($c-text-secondary);
}
.c-text-platform-tertiary {
  color: getColor($c-text-tertiary);
}
.c-text-platform-quaternary {
  color: getColor($c-text-quaternary);
}

.c-text-platform-inverse {
  color: getColor($c-text-inverse);
}

.c-text-platform-bold {
  color: getColor($c-text-bold);
}

// icon
.c-icon-platform-default {
  color: getColor($c-icon);
}

.c-icon-platform-tertiary {
  color: getColor($c-icon-tertiary);
}

.c-icon-platform-inverse {
  color: getColor($c-icon-inverse);
}

// hide/show

.hideOnMobile {
  @include hideOnMobile;
}

.hideOnDesktop {
  @include hideOnDesktop {
    display: block;
  }
}

.hideOnExtraMediumUp {
  @include hideOnExtraMediumUp {
    display: block;
  };
}

.stickyBottom {
  position: sticky;
  bottom: 0;
}

.text-center-medium {
  @include breakpoint(medium down) {
    text-align: center;
  }
}

;@include ---g5gaorbcwz.load-css("sass-embedded-legacy-load-done:75147");